<template>
  <div class="share-menu">
    <span class="title">Поделиться</span>
    <div class="container">
      <button class="button email" @click="share('email')">
        <span class="text">Email</span>
        <span class="mask-icon"></span>
      </button>
      <button class="button vk" @click="share('vk')">
        <span class="text">VK</span>
        <span class="mask-icon"></span>
      </button>
      <button class="button telegram" @click="share('tg')">
        <span class="text">Telegram</span>
        <span class="mask-icon"></span>
      </button>
      <button class="button whatsapp" @click="share('whatsapp')">
        <span class="text">WhatsApp</span>
        <span class="mask-icon"></span>
      </button>
      <button class="button viber" @click="share('viber')">
        <span class="text">Viber</span>
        <span class="mask-icon"></span>
      </button>
      <button class="button ok" @click="share('ok')">
        <span class="text">OK</span>
        <span class="mask-icon"></span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    share_url: String,
  },
  methods: {
    share(app) {
      let shareUrl;
      switch (app) {
        case "viber":
          shareUrl = `viber://forward?text=${encodeURIComponent(
            this.share_url
          )}`;
          break;
        case "whatsapp":
          shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            this.share_url
          )}`;
          break;
        case "vk":
          shareUrl = `https://vk.com/share.php?url=${encodeURIComponent(
            this.share_url
          )}&noparse=false`;
          break;
        case "tg":
          shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
            this.share_url
          )}`;
          break;
        case "ok":
          shareUrl = `https://connect.ok.ru/offer?url=${encodeURIComponent(
            this.share_url
          )}`;
          break;
        case "email":
          shareUrl = `mailto:?body=${encodeURIComponent(this.share_url)}`;
          break;
        default:
          return;
      }
      this.$emit("close-share");
      window.open(shareUrl, "_blank");
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/styles/base/adaptive"
.share-menu
  position: absolute
  z-index: 999999
  height: fit-content
  display: flex
  flex-direction: column
  border-radius: 5px
  background: var(--white-color)
  overflow: hidden
  .title
    color: var(--dark-blue-color)
    font-size: 0.875rem
    font-weight: 600
    line-height: 100%
    padding: 18px 35px
    text-align: center
    border: 1px solid var(--gray-lines-color)
  .container
    display: flex
    flex-direction: column
    height: 100%
    overflow: hidden
    .button
      padding: unset
      width: 100%
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      transform: perspective(1px) translateZ(0)
      position: relative
      transition: color 0.3s
      .text
        text-align: start
        width: 160px
        padding: 13px 15px
        font-size: 0.875rem
        font-weight: 600
        transition: .3s ease-out
        color: var(--dark-blue-color)
      .mask-icon
        margin: 10px
        transition: .3s ease-out
        display: flex
        width: 20px
        height: 20px
      &:before
        content: ""
        position: absolute
        z-index: -1
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: #2098D1
        transform: scaleY(0)
        transform-origin: 50% 100%
        transition: transform 0.3s ease-out
    .button:hover, .button:focus, .button:active
      .mask-icon
        background-color: var(--white-color)
      .text
        transition: .3s ease-out
        color: white
    .button:hover:before, .button:focus:before, .button:active:before
      transform: scaleY(1)
    .email
      &:before
        background: var(--main-color)
      .mask-icon
        mask: url(@/assets/images/icons/mail-icon.svg) no-repeat center
        mask-size: contain
        background-color: var(--main-color)
    .vk
      &:before
        background: #0073F6
      .mask-icon
        mask: url(@/assets/images/icons/vk-icon.svg) no-repeat center
        mask-size: contain
        background-color: #0073F6
    .telegram
      &:before
        background: #18A3E6
      .mask-icon
        mask: url(@/assets/images/icons/tg-icon.svg) no-repeat center
        mask-size: contain
        background-color: #18A3E6
    .whatsapp
      &:before
        background: #26D367
      .mask-icon
        mask: url(@/assets/images/icons/whatsapp-icon.svg) no-repeat center
        mask-size: contain
        background-color: #26D367
    .viber
      &:before
        background: #794F99
      .mask-icon
        mask: url(@/assets/images/icons/viber-icon.svg) no-repeat center
        mask-size: contain
        background-color: #794F99
    .ok
      &:before
        background: #FF8500
      .mask-icon
        mask: url(@/assets/images/icons/ok-icon.svg) no-repeat center
        mask-size: contain
        background-color: #FF8500
    .pinterest
      &:before
        background: #E71D27
      .mask-icon
        mask: url(@/assets/images/icons/pintrest-icon.svg) no-repeat center
        mask-size: contain
        background-color: #E71D27
@media only screen and (max-width: $bp-pc)
  .share-menu
    .container
      .button
        &:before
          content: none
      .button:hover, .button:focus, .button:active
        .text
          color: var(--dark-blue-color)
      .button:hover:before, .button:focus:before, .button:active:before
        transform: scaleY(1)
      .email
        &:hover
          .mask-icon
            background-color: var(--main-color)
      .vk
        &:hover
          .mask-icon
            background-color: #0073F6
      .telegram
        &:hover
          .mask-icon
            background-color: #18A3E6
      .whatsapp
        &:hover
          .mask-icon
            background-color: #26D367
      .viber
        &:hover
          .mask-icon
            background-color: #794F99
      .ok
        &:hover
          .mask-icon
            background-color: #FF8500
      .pinterest
        &:hover
          .mask-icon
            background-color: #E71D27
@media only screen and (max-width: $bp-tablet)
  .share-menu
    .container
      display: flex
      flex-direction: column
      height: 100%
      overflow: hidden
      .button
        padding: unset
        width: 100%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        transform: perspective(1px) translateZ(0)
        position: relative
        transition: color 0.3s
        .text
          text-align: start
          width: 160px
          padding: 13px 15px
          font-size: 0.875rem
          font-weight: 600
          transition: .3s ease-out
          color: var(--dark-blue-color)
        .mask-icon
          margin: 10px
          transition: .3s ease-out
          display: flex
          width: 20px
          height: 20px
</style>
