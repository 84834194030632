import { createRouter, createWebHistory } from "vue-router";

import { routes } from "./routes";
import { store } from "@/store";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from) => {
    if (
      to.name === "news" &&
      from.name === "news" &&
      to.query.page !== from.query.page
    ) {
      return false;
    }
    return window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  },
});
router.afterEach(() => {
  store.commit("closeMenu");

  if (
    router.currentRoute.value.params.locale &&
    router.currentRoute.value.params.locale !== "ru" &&
    router.currentRoute.value.params.locale !== "en" &&
    router.currentRoute.value.params.locale !== "it"
  ) {
    router.push({ name: "error" });
  }

  // if (router.currentRoute.value.params.locale !== "r1u") {
  //   router.push({ name: "error" });
  // }

  // if (
  //   router.currentRoute._rawValue.params.locale !== "" ||
  //   router.currentRoute._rawValue.params.locale !== "ru" ||
  //   router.currentRoute._rawValue.params.locale !== "en" ||
  //   router.currentRoute._rawValue.params.locale !== "it"
  // ) {
  //   router.push({ name: "error" });
  // }

  // if (router. !== "Home") {
  //   document.documentElement.style.overflowY = "auto";
  // }
});
export default router;
