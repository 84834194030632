import { createStore } from "vuex";
export const store = createStore({
  state: {
    companies: [
      {
        nameTranslit: "bvk",
        nameAPI: "ООО «БВК»",
        nameAPI_en: "BVK LLC",
      },
      {
        nameTranslit: "skt",
        nameAPI: "ООО «СКТ»",
        nameAPI_en: "not_used",
      },
      {
        nameTranslit: "konar",
        nameAPI: "АО «КОНАР»",
        nameAPI_en: "not_used",
      },
      {
        nameTranslit: "kornet",
        nameAPI: "ООО «КОРНЕТ»",
        nameAPI_en: "not_used",
      },
      // {
      //   nameTranslit: "ntc",
      //   nameAPI: "ООО «НТЦ»",
      //   nameAPI_en: "not_used",
      // },
      // {
      //   nameTranslit: "chimolai",
      //   nameAPI: "ООО «СПК-Чимолаи»",
      //   nameAPI_en: "not_used",
      // },
      // {
      //   nameTranslit: "konar-orion",
      //   nameAPI: "ООО «КОНАР-ОРИОН»",
      //   nameAPI_en: "not_used",
      // },
      // {
      //   nameTranslit: "mt",
      //   nameAPI: "ООО «КОНАР Морские технологии»",
      //   nameAPI_en: "not_used",
      // },
      {
        nameTranslit: "stankomash",
        nameAPI: "ООО Индустриальный парк «Станкомаш»",
        nameAPI_en: "not_used",
      },
      {
        nameTranslit: "cok",
        nameAPI: "ЦОК КОНАР",
        nameAPI_en: "not_used",
      },
    ],
    divisions: [
      {
        nameTranslit: "litejno-kuznechnyj-divizion",
        nameAPI: "Литейно-кузнечный дивизион",
        nameAPI_en: "Foundry and Forging Division",
      },
      {
        nameTranslit: "mekhanicheskij-divizion",
        nameAPI: "Механический дивизион",
        nameAPI_en: "Mechanical Division",
      },
      {
        nameTranslit: "divizion-metallokonstrukcij",
        nameAPI: "Дивизион металлоконструкций",
        nameAPI_en: "Metal structures division",
      },
    ],
    employee: [
      {
        nameTranslit: "ohrana-zdorovya-i-truda",
        nameAPI: "Охрана здоровья и труда",
        nameAPI_en: "Health and safety",
      },
      {
        nameTranslit: "deti",
        nameAPI: "Дети",
        nameAPI_en: "Childrens",
      },
      {
        nameTranslit: "sport",
        nameAPI: "Спорт",
        nameAPI_en: "Sport",
      },
      {
        nameTranslit: "muzej-zavoda-stankomash",
        nameAPI: "Музей завода «Станкомаш»",
        nameAPI_en: "Museum of the Stankomash plant",
      },
      {
        nameTranslit: "veterany",
        nameAPI: "Ветераны",
        nameAPI_en: "Veterans",
      },
    ],
    production: [
      {
        nameTranslit: "flancy",
        nameAPI: "Фланцы",
        nameAPI_en: "not_used",
      },
      {
        nameTranslit: "krepezh",
        nameAPI: "Крепеж",
        nameAPI_en: "not_used",
      },
      {
        nameTranslit: "zaglushki",
        nameAPI: "Заглушки",
        nameAPI_en: "not_used",
      },
      {
        nameTranslit: "krupnogabaritnaya-truboprovodnaya-armatura",
        nameAPI: "КРУПНОГАБАРИТНАЯ ТРУБОПРОВОДНАЯ АРМАТУРА",
        nameAPI_en: "not_used",
      },
      {
        nameTranslit: "truboprovodnaya-armatura-specialnogo-primeneniya",
        nameAPI: "Трубопроводная арматура специального применения",
        nameAPI_en: "not_used",
      },
      {
        nameTranslit: "fontannaya-armatura-i-ustevoe-oborudovanie",
        nameAPI: "ФОНТАННАЯ АРМАТУРА И УСТЬЕВОЕ ОБОРУДОВАНИЕ",
        nameAPI_en: "not_used",
      },
    ],
    isMenuOpen: false,
  },
  mutations: {
    toggleMenu(state) {
      state.isMenuOpen = true;
    },
    closeMenu(state) {
      state.isMenuOpen = false;
    },
  },
});
