<template>
  <Head>
    <title>Промышленная группа КОНАР</title>
    <meta property="og:title" content="Промышленная группа КОНАР" />
    <link rel="icon" href="/favicon.ico" />
    <link rel="icon" href="/favicon.png" />
    <meta name="description" content="" />
    <meta property="og:description" content="" />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Промышленная группа КОНАР" />

    <meta property="og:locale" content="ru_RU" />
  </Head>
  <template v-if="showNewHeader">
    <!-- Эта иконка нужна, чтоб у всех компонентов был доступ к фильтру для закругления краев в clip-path -->
    <svg
      style="visibility: hidden; position: absolute"
      width="0"
      height="0"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
            result="goo"
          />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
      </defs>
    </svg>
    <svg
      style="visibility: hidden; position: absolute"
      width="0"
      height="0"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs>
        <filter id="border-10">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
            result="goo"
          />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
      </defs>
    </svg>
    <svg
      style="visibility: hidden; position: absolute"
      width="0"
      height="0"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs>
        <filter id="border-5">
          <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
            result="goo"
          />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
      </defs>
    </svg>
    <!-- Эта иконка нужна, чтоб у всех компонентов был доступ к фильтру для закругления краев в clip-path -->
    <the-header :activeSlide="activeSlide" @to-top="setSliderStart = true" />
    <div class="main-content">
      <router-view
        :setSliderStart="setSliderStart"
        @set-slider-null="setSliderStart = false"
        @sidebar-color="getIndex"
      />
      <the-footer @goToTop="setSliderStart = true" />
      <div class="overlayNew"></div>
    </div>
  </template>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import { Head } from "@vueuse/head";
import ShareModal from "@/components/UI/shareModal.vue";
export default {
  components: {
    TheHeader,
    TheFooter,
    Head,
  },
  data() {
    return {
      activeSlide: 0,
      setSliderStart: false,
    };
  },
  computed: {
    showNewHeader() {
      return this.$route.name;
    },
  },
  methods: {
    getIndex(index) {
      this.activeSlide = index;
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/styles/base/adaptive"
#app
  display: flex
  flex-direction: column
  .main-content
    display: flex
    flex-direction: column
    flex: 1 0 0
    width: 100%
    height: 100%
    z-index: 4
    .overlay
      position: fixed
      top: 0
      left: 0
      width: 100vw
      height: 100svh
      background: var(--white-color)
      opacity: 0
      pointer-events: none
      z-index: 9999
//@media only screen and (max-width: $bp-pc)
//  #app
//    flex-direction: column
</style>
