<script>
export default {
  name: "search-comp",
  props: {
    dark: Boolean,
  },
  data() {
    return {
      query: "",
    };
  },
  methods: {
    sendRequest() {
      if (this.query) {
        this.$router.push({
          name: "search-page",
          query: { search: this.query },
        });
        this.query = "";
      }
    },
  },
};
</script>

<template>
  <div class="search-comp" :class="{ 'search-comp--dark': dark }">
    <div class="search-content">
      <input
        type="text"
        class="search-input"
        v-model="query"
        placeholder="Поиск"
        @keydown.enter="sendRequest"
      />
      <button class="icon" @click="sendRequest">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <g clip-path="url(#clip0_7137_51333)">
            <path
              d="M5.2 0C6.57913 0 7.90177 0.547855 8.87696 1.52304C9.85215 2.49823 10.4 3.82087 10.4 5.2C10.4 6.488 9.928 7.672 9.152 8.584L9.368 8.8H10L14 12.8L12.8 14L8.8 10V9.368L8.584 9.152C7.64029 9.95717 6.44052 10.3996 5.2 10.4C3.82087 10.4 2.49823 9.85215 1.52304 8.87696C0.547855 7.90177 0 6.57913 0 5.2C0 3.82087 0.547855 2.49823 1.52304 1.52304C2.49823 0.547855 3.82087 0 5.2 0ZM5.2 1.6C3.2 1.6 1.6 3.2 1.6 5.2C1.6 7.2 3.2 8.8 5.2 8.8C7.2 8.8 8.8 7.2 8.8 5.2C8.8 3.2 7.2 1.6 5.2 1.6Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_7137_51333">
              <rect width="14" height="14" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<style scoped lang="sass">
@import "@/assets/styles/base/adaptive"
.search-comp
  padding-top: 10px
  .search-content
    margin: 0 30px
    border-bottom: 1px solid var(--white-color)
    display: flex
    flex-direction: row
    align-items: center
    position: relative
    height: 38px
    .search-input
      background: transparent
      padding: 10px 0
      font-size: 0.875rem
      font-weight: 600
      line-height: 100%
      width: 100%
      color: var(--white-color)
      &::placeholder
        color: var(--white-color)
        text-transform: uppercase
    .icon
      display: flex
      justify-content: center
      align-items: center
      height: 100%
      width: 100%
      max-width: 38px
      padding: 0
      svg
        path
          fill: var(--white-color)
  &--dark
    .search-content
      border-bottom-color: var(--dark-blue-color)
      .search-input
        color: var(--text-color)
        &::placeholder
          color: var(--dark-blue-color)
      .icon
        svg
          path
            fill: var(--text-color)
@media only screen and (max-width: $bp-tablet-landscape-up)
  .search-comp
    .search-content
      margin: 0 10px
@media only screen and (max-width: $bp-tablet)
  .search-comp
    .search-content
      margin: 0
</style>
