<template>
  <img
    v-if="dark"
    :src="require(`@/assets/images/logos/${logoName}.png`)"
    alt
  />
  <img v-else src="@/assets/images/KONAR-White-logo.webp" alt />
</template>

<script>
export default {
  data() {
    return {
      logoName: "konar-pg",
    };
  },
  methods: {
    getLogo() {
      const companies = this.$store.state.companies;

      this.logoName = "konar-pg";
      companies.forEach((company) => {
        if (
          this.$route.fullPath.includes(company.nameTranslit) &&
          company.nameTranslit !== "cok"
        ) {
          this.logoName = company.nameTranslit;
        }
      });
    },
  },
  created() {
    this.getLogo();
  },
  watch: {
    $route: {
      handler() {
        this.getLogo();
      },
      deep: true,
    },
  },
  props: {
    dark: Boolean,
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/base/adaptive'
.dark
  path
    fill: var(--main-color)

@media only screen and (max-width: $bp-tablet)
  svg
    width: fit-content
    height: 20px
</style>
