import { RouterView } from "vue-router";

import {
  getCompanies,
  getNews,
  getNewsEnterpriseCount,
  getNewsEnterprises,
} from "../helpers/getDynamicRoutes";
import { neededCompanies } from "../helpers/getDynamicRoutes";

function createEnterpriseRoutes() {
  return neededCompanies.map((enterprise) => ({
    path: `_sitemap_${enterprise}/news`,
    component: RouterView,
    beforeEnter: (to, from, next) => {
      // Проверяем, если запрос идет не от генерации карты сайта
      if (process.env.NODE_ENV !== "sitemap") {
        next({ path: "/error" });
      } else {
        next();
      }
    },
    children: [
      {
        path: "",
        component: () => import("@/views/News/NewsPage.vue"),
        beforeEnter: (to, from, next) => {
          // Проверяем, если запрос идет не от генерации карты сайта
          if (process.env.NODE_ENV !== "sitemap") {
            next({ path: "/error" });
          } else {
            next();
          }
        },
      },
      {
        path: ":slug",
        component: () => import("@/views/News/NewsItemPage.vue"),
        beforeEnter: (to, from, next) => {
          // Проверяем, если запрос идет не от генерации карты сайта
          if (process.env.NODE_ENV !== "sitemap") {
            next({ path: "/error" });
          } else {
            next();
          }
        },
        meta: {
          sitemap: {
            slugs: async () => await getNewsEnterprises(enterprise),
          },
        },
      },
    ],
  }));
}

export const companiesRoutes = [
  {
    path: "enterprise",
    component: RouterView,
    children: [
      {
        path: "stankomash",
        name: "stankomash",
        children: [
          {
            path: "",
            name: "stankomash-home",
            component: () =>
              import("@/views/templates/stankomash/HomePage.vue"),
          },
          {
            path: "engineering-infrastructure",
            name: "stankomash-engineering-infrastructure",
            component: () =>
              import("@/views/templates/stankomash/EngineeringInfra.vue"),
          },
          {
            path: "ecology",
            name: "stankomash-ecology",
            component: () =>
              import("@/views/templates/stankomash/EcologyPage.vue"),
          },
          {
            path: "contacts",
            name: "stankomash-contacts",
            component: () =>
              import("@/views/templates/companies/CompanyContacts.vue"),
          },
          {
            path: "photo-gallery",
            name: "stankomash-photo-gallery",
            component: () => import("@/views/Company/PhotoGalleryPage.vue"),
          },
          {
            path: "news",
            component: RouterView,
            children: [
              {
                path: "",
                name: "stankomash-news",
                component: () => import("@/views/News/NewsPage.vue"),
                // meta: {
                //   sitemap: {
                //     slugs: async () => await getNewsCount("stankomash-news"),
                //   },
                // },
              },
              {
                path: ":slug",
                name: "stankomash-news-item",
                component: () => import("@/views/News/NewsItemPage.vue"),
                meta: {
                  sitemap: {
                    slugs: async () => await getNews("stankomash"),
                  },
                },
              },
            ],
          },
          {
            path: "information",
            name: "stankomash-information",
            component: () =>
              import("@/views/templates/stankomash/InfoPage.vue"),
          },
          {
            path: "management-company",
            name: "stankomash-management-company",
            component: () =>
              import("@/views/templates/stankomash/ManageCompany.vue"),
          },
          {
            path: "investors",
            name: "stankomash-investors",
            component: () =>
              import("@/views/templates/stankomash/InvestPage.vue"),
          },
        ],
      },
      {
        path: ":enterprise",
        component: RouterView,
        meta: {
          sitemap: {
            slugs: async () => getCompanies(),
          },
        },
        children: [
          {
            path: "",
            name: "company-home",
            component: () =>
              import("@/views/templates/companies/CompanyHome.vue"),
          },
          {
            path: "news",
            component: RouterView,
            meta: { sitemap: { ignoreRoute: true } },
            children: [
              {
                path: "",
                name: "company-news",
                component: () => import("@/views/News/NewsPage.vue"),
                meta: { sitemap: { ignoreRoute: true } },
              },
              {
                path: ":slug",
                name: "company-news-item",
                component: () => import("@/views/News/NewsItemPage.vue"),
                meta: { sitemap: { ignoreRoute: true } },
              },
            ],
          },
          {
            path: "history",
            name: "company-history",
            component: () =>
              import("@/views/templates/companies/CompanyHistory.vue"),
          },
          {
            path: "photo-gallery",
            name: "company-photo-gallery",
            component: () => import("@/views/Company/PhotoGalleryPage.vue"),
          },
          {
            path: "equipment",
            name: "company-equipment",
            component: () =>
              import("@/views/templates/companies/CompanyEquipment.vue"),
          },
          {
            path: "nmu",
            name: "company-nmu",
            component: () =>
              import("@/views/templates/companies/CompanyNmu.vue"),
          },
          {
            path: "quality-control",
            name: "company-quality-control",
            component: () =>
              import("@/views/templates/companies/CompanyControl.vue"),
          },
          {
            path: "results",
            name: "company-results",
            component: () =>
              import("@/views/templates/companies/CompanyResults.vue"),
          },
          {
            path: "contacts",
            name: "company-contacts",
            component: () =>
              import("@/views/templates/companies/CompanyContacts.vue"),
          },
          {
            path: "certificates",
            name: "company-serts",
            component: () => import("@/views/Company/SertsCompanyPage.vue"),
          },
        ],
      },
      ...createEnterpriseRoutes(),
    ],
  },
];
