<template>
  <svg
    @click="$emit('clicked')"
    :class="{ dark: dark }"
    width="80"
    height="24"
    viewBox="0 0 80 24"
    fill="none"
    class="burger-icon"
    xmlns="http://www.w3.org/2000/svg"
    v-if="width > 600"
  >
    <g clip-path="url(#clip0_1807_357)">
      <rect width="80" height="4" fill="var(--white-color)" />
      <rect y="20" width="80" height="4" fill="var(--white-color)" />
      <rect y="10" width="80" height="4" fill="var(--white-color)" />
    </g>
    <defs>
      <clipPath id="clip0_1807_357">
        <rect width="80" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
  <svg
    @click="$emit('clicked')"
    class="burger-icon"
    :class="{ dark: dark }"
    width="40"
    height="22"
    viewBox="0 0 40 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-if="width <= 600"
  >
    <rect width="40" height="3" fill="var(--white-color)" />
    <rect y="18" width="40" height="3" fill="var(--white-color)" />
    <rect y="9" width="40" height="3" fill="var(--white-color)" />
  </svg>
</template>

<script>
import { useWindowSize } from "@vueuse/core";
const { width } = useWindowSize();
export default {
  props: {
    dark: Boolean,
  },
  data() {
    return {
      width: width,
    };
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/base/adaptive'
.dark
  rect
    fill: var(--main-color)
@media only screen and (max-width: $bp-pc)
  .burger-icon
      justify-self: end
      align-self: center
      margin: 0 65px
@media only screen and (max-width: $bp-tablet-landscape-up)
  .burger-icon
      width: 60px
      margin: 0 45px
@media only screen and (max-width: $bp-tablet)
  .burger-icon
      height: 22px
      width: 40px
      margin: 0 17px
</style>
