<template>
  <div
    class="lang-switcher"
    :class="{
      'lang-switcher--black': blackTheme,
    }"
  >
    <div class="toggle">
      <template v-for="(lang, index) in options" :key="index">
        <input
          type="radio"
          :id="`radio-${index + 1}`"
          name="tabs"
          :value="lang"
          v-model="selectedLang"
        />
        <label class="option" :for="`radio-${index + 1}`">{{ lang }}</label>
      </template>
      <span class="glider"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    blackTheme: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedLang: "ru",
    };
  },
  beforeMount() {
    this.selectedLang = this.$i18n.locale;
  },
  mounted() {
    this.$emit("select-language", this.selectedLang);
  },
  watch: {
    selectedLang(lang) {
      this.$emit("changeLang", lang);
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/styles/base/adaptive'
.lang-switcher
    padding: 35px
    padding-top: 0
    display: flex
    justify-content: center
    .toggle
        width: 160px
        padding: 5px
        display: flex
        flex-direction: row
        position: relative
        border-radius: 100px
        background: rgba(255, 255, 255, 0.1)
        transition: .3s ease-out
        input[type="radio"]
            display: none
        input[type="radio"]:checked + label
            color: var(--text-color)
            transition: 0.3s ease-out
        input[id="radio-1"]:checked ~ .glider
            transform: translateX(0)
            transition: 0.3s ease-out
        input[id="radio-2"]:checked ~ .glider
            transform: translateX(100%)
            transition: 0.3s ease-out
        input[id="radio-3"]:checked ~ .glider
            transform: translateX(200%)
            transition: 0.3s ease-out
        .glider
            position: absolute
            display: flex
            width: 50px
            border-radius: 50%
            background-color: var(--white-color)
            z-index: 1
            height: 50px
            transition: 0.3s ease-out
            box-shadow: 0 0 5px 5px rgba(#191619FF, 5%)
        .option
            z-index: 2
            text-align: center
            width: 50px
            height: 50px
            color: #BBBABA
            font-size: 0.875rem
            font-weight: 600
            line-height: 100%
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer
            transition: 0.3s ease-out
.lang-switcher--black
    --lines-color: #EDF2F8
    border-right: 1px solid var(--lines-color)
    z-index: 5
    .toggle
      background: var(--bg-gray-color)
</style>
