import { store } from "../store";

const ApiURL = process.env.BACKEND_HOST
  ? process.env.BACKEND_HOST
  : process.env.VUE_APP_BACKEND_HOST;

let neededCompanies = store.state.companies.filter(
  (company) =>
    company.nameTranslit !== "cok" &&
    company.nameTranslit !== "ntc" &&
    company.nameTranslit !== "mt" &&
    company.nameTranslit !== "stankomash" &&
    company.nameTranslit !== "konar-orion" &&
    company.nameTranslit !== "chimolai"
);
neededCompanies = neededCompanies.map((item) => item.nameTranslit);

const getNewsCount = async (enterprise) => {
  let response;
  if (!enterprise) {
    response = await fetch(`${ApiURL}/news?limit=999999999999&lang=ru`);
  } else {
    if (enterprise === "stankomash-news") {
      response = await fetch(
        `${ApiURL}/news?limit=999999999999&lang=ru&show_stankomash=true`
      );
    } else {
      response = await fetch(
        `${ApiURL}/news?limit=999999999999&lang=ru&show_${enterprise}=true`
      );
    }
  }
  const count = response.headers.get("total-count");
  let result = [];
  for (let i = 1; i <= Math.ceil(count / 5); i++) {
    result.push(i);
  }
  return result;
};
const getNews = async (enterprise) => {
  let news = [];
  let responseRU;
  if (enterprise === "gk_konar") {
    responseRU = await fetch(
      `${ApiURL}/news?limit=99999&page=1&lang=ru&show_gk_konar=true`
    );
  } else if (enterprise === "stankomash") {
    responseRU = await fetch(
      `${ApiURL}/news?limit=99999&page=1&lang=ru&show_stankomash=true`
    );
  } else {
    responseRU = await fetch(
      `${ApiURL}/news?limit=99999&page=1&lang=ru&show_${enterprise}=true`
    );
  }
  const newsRU = await responseRU.json();
  // const responseEN = await fetch(`${ApiURL}/news?limit=2&page=1&lang=en`)
  // const newsEN = await responseEN.json();
  // const responseIT = await fetch(`${ApiURL}/news?limit=2&page=1&lang=it`)
  // const newsIT = await responseIT.json();

  news = [...newsRU];

  return news.map((item) => item.url_string);
  // return ["true"];
};
const getNewsEnterprises = async () => {
  let news = [];
  let responseRU;

  for (const comp of neededCompanies) {
    responseRU = await fetch(
      `${ApiURL}/news?limit=999999999999&page=1&lang=ru&show_${comp}=true`
    );
    const newsRU = await responseRU.json();
    // const responseEN = await fetch(`${ApiURL}/news?limit=2&page=1&lang=en`)
    // const newsEN = await responseEN.json();
    // const responseIT = await fetch(`${ApiURL}/news?limit=2&page=1&lang=it`)
    // const newsIT = await responseIT.json();

    news = [...newsRU];
  }
  return news.map((item) => item.url_string);
  // return ["true"];
};

const getNewsEnterpriseCount = async (enterprise) => {
  let response;
  response = await fetch(
    `${ApiURL}/news?limit=99999&lang=ru&show_${enterprise}=true`
  );

  let result = [];

  for (const comp of neededCompanies) {
    const count = response.headers.get("total-count");
    for (let i = 1; i <= Math.ceil(count / 5); i++) {
      result.push(i);
    }
  }
  return result;
};

const getEmployees = async () => {
  return store.state.employee.map((item) => item.nameTranslit);
};
const getDivisions = async () => {
  return store.state.divisions.map((item) => item.nameTranslit);
};
const getProducts = async () => {
  return store.state.production.map((item) => item.nameTranslit);
};
const getCompanies = async () => {
  const neededCompanies = store.state.companies.filter(
    (company) =>
      company.nameTranslit !== "cok" &&
      company.nameTranslit !== "ntc" &&
      company.nameTranslit !== "mt" &&
      company.nameTranslit !== "konar-orion" &&
      company.nameTranslit !== "stankomash" &&
      company.nameTranslit !== "chimolai"
  );
  return neededCompanies.map((item) => item.nameTranslit);
};

export {
  neededCompanies,
  getNewsCount,
  getNews,
  getEmployees,
  getDivisions,
  getProducts,
  getCompanies,
  getNewsEnterprises,
  getNewsEnterpriseCount,
};
