export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "toTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наверх"])},
        "block1": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПГ КОНАР"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["о компании"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company/about-company"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["новости"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["news"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["контакты"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacts"])}
          }
        ],
        "block2": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сертификаты"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serts"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["фотогалерея"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company/photo-gallery"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["видеогалерея"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company/video-gallery"])}
          }
        ]
      },
      "en": {
        "toTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to top"])},
        "block1": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IG KONAR"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company/about-company"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["news"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["news"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacts"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacts"])}
          }
        ],
        "block2": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificates"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serts"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["photo gallery"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company/photo-gallery"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video gallery"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company/video-gallery"])}
          }
        ]
      },
      "it": {
        "toTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to top"])},
        "block1": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GI KONAR"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company/about-company"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["news"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["news"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacts"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacts"])}
          }
        ],
        "block2": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificates"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serts"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["photo gallery"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company/photo-gallery"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video gallery"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company/video-gallery"])}
          }
        ]
      }
    }
  })
}
